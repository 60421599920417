<template>
    <div>
        <h5>You have selected {{this.selected.length}} order{{this.selected.length > 1 ? 's':'' }} to update status</h5>
        <div class="form-group mb-3">
            <label class="">Status</label>
            <select class="custom-select" v-model="data.status" v-validate="{required: true}" 
                name="status" placeholder="--select status--">
                <option disabled value="" key="-1">-- select status--</option>
                <option :value="status.value" v-for="(status, index) in statusList" :key="index">{{status.name}}</option>
            </select>
            <span class="text-danger invalid-feedback"  v-show="errors.has('status')">{{ errors.first('status') }}</span>
        </div>
        <div class="form-group text-right mt-3">
            <b-button variant="primary" @click.prevent="updateStatus()" type="button">Update</b-button>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            data:{
                status:""
            },
            statusList:[
                {name: "Draft", value:"draft"},
                {name: "Pending", value:"pending"},
                {name: "Delivered", value:"delivered"},
                {name: "Completed", value:"completed"},
                {name: "Refunded", value:"refunded"},
            ]
        }
    },
    props:{
        selected:{
            type: Array
        }
    },
    methods:{
        updateStatus() {
            this.$validator.validateAll().then(result => {
                if (!result) { this.alertError("Form not valid"); return;}
                Swal.fire({
                    title: "Are you sure?",
                    text: "Please confirm this action before proceed",
                    reverseButtons:true,
                    showCancelButton: true,
                    confirmButtonColor: "#1abc9c",
                    cancelButtonColor: "#111",
                    confirmButtonText: "Confirm",
                }).then((result) => {
                    if (result.value) {
                        const formData = new FormData()
                        formData.append('data', JSON.stringify({orders: this.selected, status: this.data.status}))
                        this.$store.dispatch("changeLoaderValue",true)
                        this.$http.post(`/orders/update-status`, formData)
                        .then(response => {
                            this.$store.dispatch("changeLoaderValue",false)
                            if(response.data.success){
                                this.$store.dispatch("orderList/fetchOrders", true)
                                this.$emit('feedback');
                            }
                        })
                    }
                });
            })
        },
    }
}
</script>